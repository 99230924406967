<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="60%"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{
              this.reportUrl == "master"
                ? "List Senior Statement"
                : this.reportUrl == "agent"
                ? "List Master Statement"
                : this.reportUrl == "player"
                ? "List Agent Statement"
                : this.reportUrl == "playerDetail"
                ? "List Player Statement"
                : "Loading ..."
            }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>
        <v-card-text>
          <br />
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-search"></i>
              </div>
            </div>
            <input v-model="v_search" type="text" class="form-control mr-1" />
            <button
              class="btn btn-primary"
              color="green darken-1"
              depressed
              @click="search"
            >
              SEARCH
            </button>
          </div>
          <br />

          <v-data-table
            style="font-family: 'khmer mef1'; text-align: center;"
            class="elevation-1"
            item-key="_id"
            :headers="headers"
            :footer-props="footerProps"
            :items="users ? users.object : []"
            :server-items-length="users ? users.objectCount.count : null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="$emit('getData4')"
            @update:items-per-page="$emit('getData4')"
            @update:sort-desc="$emit('getData4')"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>
                  <v-icon
                    color="green"
                    small
                    class="mr-2"
                    @click="getUserChild5(item)"
                    >{{ reportUrl ? "mdi-eye" : "mdi-eye-off" }}
                  </v-icon>
                </td>
                <td>{{ users.object.indexOf(item) + 1 }}</td>
                <td>{{ item._id.app_id }}</td>
                <td>{{ item._id.user_name }}</td>
                <td>{{ currencyFormat(item._id.balance) }}</td>
                <td class="amount-bet-color">
                  {{ currencyFormat(item.amount) }}
                </td>
                <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">
                  {{ currencyFormat(item.amount_win) }}
                </td>
              </tr>
            </template>
            <template slot="body.append">
              <tr style="background-color: #cffadb; font-weight: bold;">
              <td :colspan="4" class="text-xs-center pl-4 py-2">
                {{ $t("title.total") }}
              </td>
              <td class="text-xs-center total-style pl-4 py-2">
                {{
                  users.objectCount.balance
                    ? currencyFormat(users.objectCount.balance)
                    : 0
                }}
              </td>
              <td class="total-style">
                {{
                  users.objectCount.amount
                    ? currencyFormat(users.objectCount.amount)
                    : 0
                }}
              </td>
              <td
                class="total-style font-weight-bold"
                :style="
                  users.objectCount.amount_win < 0
                    ? 'color:#BA2D4A'
                    : 'color:#39afd6'
                "
              >
                {{
                  users.objectCount.amount_win
                    ? currencyFormat(users.objectCount.amount_win)
                    : 0
                }}
              </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <statement-list5
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5(item)"
      :date="date"
      :user="user"
      @onSearch="onSearch"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import headers from "../../_api/columns";
import StatementList5 from "./Dialog5";
import Vue from "vue";

export default {
  components: { StatementList5 },
  props: ["darkmode", "dialog", "users", "dataRequest", "user", "date"],
  data: () => {
    return {
      dialogListUser: false,
      headers: headers,
      userChild5: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      item: null,
      data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "sn_number.name",
          "sn_number.desc",
          "sn_number.payout",
          "date",
          "fight_no",
          "is_win",
          "amount",
          "amount_win",
          "cast",
        ],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      params: "",
    };
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "playerDetail";
        else return null;
      } else return null;
    },
  },
  methods: {
    getUserChild5(item, search = "") {
      if (search) this.data5.search = search;
      else this.data5.search = "";
      if (this.date) {
        this.data5.startDate = this.date.startDate;
        this.data5.endDate = this.date.endDate;
      }
      this.item = item;
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 2, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data5,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    onSearch(search) {
      this.getUserChild5(this.item, search);
    },
    search() {
      this.$emit("onSearch", this.v_search);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      return parseInt(num).toLocaleString("en");
    },
  },
};
</script>

<style scoped>
.btn-custom {
  position: relative;
  top: 10px;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
</style>
